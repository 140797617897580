import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import { Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';


export default function TicketAlert() {
    const [ticketInfo, setTicketInfo] = useState([]);

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_PATH}/role5/getMyTickets`
        }).then(res => {
            if (res.data.success) {
                setTicketInfo(res?.data?.data);
            } else {
                alert(res.data.message)
            }

        }).catch(err => {
            alert(err?.response?.data?.message || "something went wrong")
        })
    }, []);

    return (
        <div>

            <Dialog
                open={false}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h3'>Pending Ticket Notification</Typography>
                </DialogTitle>
                <Grid container>
                    <Grid item lg={12}><Divider /></Grid>
                </Grid>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography>You have more than 3 New Tickets or more than 6 Pending Tickets . Please resolve those tickets.</Typography>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Link
                        to={`/sales/allleads`}
                        style={{
                            textDecoration: "none",
                            backgroundColor: "#f6f6f6",
                            padding: "10px 15px",
                        }}
                    // onClick={handleClose}
                    >
                        Go To Ticket
                    </Link>


                </DialogActions>
            </Dialog>
        </div>
    );
}
