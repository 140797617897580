import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Alert,
  AlertTitle,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Stack,
  Grid,
} from "@mui/material";
import XLSX from "xlsx";
import moment from "moment";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

export default function FiveDaysOldOrderReassign() {
    const agentPhoneNumber = getDecryptDataFromLocal("phoneNumber");
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const fiveDaysOldOrders = ['7088578757','9891434580','9719836187']

  const handleSendOtp = async () => {
    setIsLoading(true);
    setError("");
    // Replace with your actual API endpoint
    axios
      .post(process.env.REACT_APP_PATH + "/api/sendOtp", {
        phoneNumber: phoneNumber,
      })
      .then(function (response) {
        if (response.data.success) {
          setStep(2);
          setIsLoading(false);
        } else {
          setError("User is invalid , Try with another number");
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setError("Failed to send OTP. Please try again.");
        setIsLoading(false);
      });
  };

  const readFileData = (file) => {
    console.log("file", file);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellDates: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      // Assuming the column index you want to extract is `0` (A column)
      const columnIndex = 0; // Change this to the column index you want (0 = A, 1 = B, etc.)
      const extractedColumn = data
        .map((row) => row[columnIndex])
        .filter((item) => !isNaN(item));
      setFileData(extractedColumn);
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if(selectedFile){
        setFile(selectedFile);
        readFileData(selectedFile);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError("");
    setSuccess("");
    try {
   let res =   await axios.post(process.env.REACT_APP_PATH+"/producst/assgin5DaysOldOrdersToFloridaUsingOtp",{
        ids:fileData, phoneNumber:phoneNumber, otp:otp
      });
      if(res.data.success){
        setSuccess(res.data?.message||"Order assignment submitted successfully!");
        setStep(1); // Reset to first step
        setPhoneNumber("");
        setOtp("");
        setFile(null);
        setFileData([]);
      }else{
        setError(res.data?.message||"Failed to submit order assignment. Please try again.");
      }

    } catch (err) {
      setError("Failed to submit order assignment. Please try again.");
    }
    setIsLoading(false);
  };

  return (
    <Card sx={{ maxWidth: 800, margin: "auto", mt: 4 }}>
      <CardHeader title="Five Days Old Order Assign To Florida" />
      <CardContent>
        {step === 1 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Customer Phone Number"
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              onClick={handleSendOtp}
              disabled={isLoading || !phoneNumber}
            >
              {isLoading ? "Sending..." : "Send OTP"}
            </Button>
          </Box>
        )}

        {step === 2 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Enter OTP"
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              inputProps={{ maxLength: 4 }}
              fullWidth
            />
            <Button variant="contained" component="label">
              Upload File
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                accept=".csv,.xlsx,.xls"
              />
            </Button>
            {fileData.length > 0 && (
              <Grid>
                  <Stack direction="row" overflow={'scroll'} flexWrap={'wrap'}>
                {fileData.map((item) => (
                    <Chip label={item} variant='outlined' style={{margin:10}} />
                ))}
                </Stack>
              </Grid>
            )}
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoading || !otp || !file||otp.length!=4||!fiveDaysOldOrders.includes(agentPhoneNumber)}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            <AlertTitle>Success</AlertTitle>
            {success}
          </Alert>
        )}
      </CardContent>
    </Card>
  );
}
