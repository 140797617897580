import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Paper, 
  Tabs, 
  Tab, 
  Typography, 
  Box 
} from '@mui/material';
import SellerDetails from './SellerDetails';
import SellerOrders from './SellerOrders';
import axios from 'axios';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function OndcSellerDetails() {
  const [value, setValue] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };




  return (
    // <Container>
      <Paper elevation={3} sx={{marginX:1,marginTop:1}}>
        <Tabs value={value} onChange={handleChange} aria-label="seller details tabs">
          <Tab label="Seller Details" />
          <Tab label="Order List" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <SellerDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SellerOrders />
        </TabPanel>
      </Paper>
    // </Container>
  );
}

