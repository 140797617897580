import React, { useEffect, useState } from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { LoadingButton } from "@material-ui/lab";
import { CSVLink } from "react-csv";
import SubCard from "../../../ui-component/cards/SubCard";

const bulkChildOrderHeader = [
  { label: "Name", key: "name" },
  { label: "Order Id", key: "numericOrderId" },
  { label: "Parent Bulk NumericOrderId", key: "parentBulkNumericOrderId" },
  { label: "Main Bulk NumericOrderId", key: "mainBulkNumericOrderId" },
  // { label: "PhoneNumber", key: "phoneNumber" },
  { label: "Total Payable Amount", key: "totalPayableAmount" },
  { label: "Is Auto Approvedt", key: "isAutoApproved" },
  { label: "Is Splited Order", key: "isSplitedOrder" },
  { label: "Created At", key: "createdAt" },
  { label: "Order Stage", key: "orderStage" },
  { label: "Status", key: "status" },
  { label: "Pushed To ProductOrder", key: "pushedToProductOrder" },
  { label: "ewayBillNumber", key: "ewayBillNumber" },
  { label: "rocketBoxInvoiceUrl", key: "rocketBoxInvoiceUrl" },
  { label: "cnDiscountPercentage", key: "cnDiscountPercentage" },
  { label: "totalMargin", key: "totalMargin" },
  { label: "ucSalesOrderCreatedAt", key: "ucSalesOrderCreatedAt" },
  { label: "zohoInvoice", key: "zohoInvoice" },
  { label: "splitted", key: "splitted" },
  { label: "zohoInvoiceDate", key: "zohoInvoiceDate" },
  { label: "zohoInvoiceUrl", key: "zohoInvoiceUrl" },
  { label: "zohoInvoiceCreditNoteNumber", key: "zohoInvoiceCreditNoteNumber" },
  { label: "ucInvoiceIdsForBulkOrder", key: "ucInvoiceIdsForBulkOrder" },
  { label: "agentName", key: "agentName" },
  { label: "awbNumber", key: "awbNumber" },
  {label: "immediateSenior", key: "immediateSenior"},
  {label: "city", key: "city"},
  {label: "state", key: "state"},
  {label: "agentId", key: "agentId"},
  {label: "sellerId", key: "sellerId"},
  {label: "shippingCost", key: "shippingCost"},
  {label: "rtoDeliveryDate", key: "rtoDeliveryDate"},
];

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
function BulkDownloadOrderData() {
  const classes = useStyles();
  const [date, setDate] = useState({
    startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const [pageLoad, togglePageLoad] = useState(false);

  const [downloadBulkData, setDownBulkData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataLoad = async () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_PATH + "/sales/downloadBulkOrderData", {
        startDate: date.startDate,
        endDate: date.endDate,
      })
      .then((res) => {
        if (res.data.success) {
          setDownBulkData(res.data.data || []);
          togglePageLoad(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error of get data", error);
      });
  };

  useEffect(() => {
    dataLoad();
  }, []);

  if (!pageLoad) {
    return (
      <div>
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <SubCard title={"Download Bulk Order Data"}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid xs={12} sm={12} md={6} lg={3} item>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.startDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.endDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <LoadingButton
            variant="contained"
            style={{
              width: "100%",
            }}
            loadingIndicator="loading..."
            onClick={() => dataLoad()}
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Grid>
        <Grid item lg={3} md={3} sm={12}>
          {!loading && downloadBulkData.length > 0 && (
            <CSVLink
              filename="BulkOrderData.csv"
              headers={bulkChildOrderHeader}
              data={downloadBulkData}
              title="Download data"
              style={{
                textDecoration: "none",
                border: "1px solid #999",
                padding: "10px 20px",
                backgroundColor: "#95c7f1",
              }}
            >
              Download data
            </CSVLink>
          )}
        </Grid>
      </Grid>
    </SubCard>
  );
}

export default BulkDownloadOrderData;
