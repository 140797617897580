import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconBuildingStore,
} from "@tabler/icons";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconBuildingStore: IconBuildingStore,

};

//-----------------------|| Customer MENU ITEMS ||-----------------------//
const role = getDecryptDataFromLocal("setRole");
const phoneNumber = getDecryptDataFromLocal("phoneNumber");


var inventory = []
var supplyChain = []
var priortizeOrder = []
const bulkMarkAccess = ["8448300516", "8826956978", "9177139113", "7001029850"]
let accessNumbers = ['9891774071', '8650090359', '7001029850']
let accessNumbers2 = ['9315481432', '7048952168', '9310646955', '8076107452', '8851954552', '9177139113', '9354530453', '8595645315', "9354815727", "8076833686"]
const replcementAccess = ["9891774071", "8650090359", "9177139113", "8851954552", "8700730373", "8076107452", "7048952168", "8076833686"]
const priortiyPanel = ['9315481432', '9354815727', '9354530453', '7048952168', '9891434580', "7982529996",'7088578757']

if (role == '4' || role == '1' || role == '2' || phoneNumber == "8076833686") {

  if (replcementAccess.includes(phoneNumber)) {
    inventory = [
      {
        id: "sellerproductlist",
        title: <FormattedMessage id="Seller Product" />,
        type: "item",
        url: "/sellerproductlist",
      },
      phoneNumber=='9315481432' && 
      {
        id: "Create Replacement Order",
        title: <FormattedMessage id="Create Replacement Order" />,
        type: "item",
        url: "/create-replacement-order",
      },
      {
        id: "addproduct",
        title: <FormattedMessage id="Add Product" />,
        type: "item",
        url: "/addproduct",
      },
      // {
      //   id: "allcombopack",
      //   title: <FormattedMessage id="Combo Packs" />,
      //   type: "item",
      //   url: "/all-combopacks",
      // },
      {
        id: "combopack",
        title: <FormattedMessage id="Add Combo Pack" />,
        type: "item",
        url: "/combopack",
      },
      {
        id: "Add Shades and Size",
        title: <FormattedMessage id="Add Shades and Size" />,
        type: "item",
        url: "/add-shades-and-size",
      },
      {
        id: "Upload Inventory",
        title: <FormattedMessage id="Upload Inventory" />,
        type: "item",
        url: "/uploadinventory",
      },
      {
        id: "Upload Forcast Inventory",
        title: <FormattedMessage id="Upload Forcast Inventory" />,
        type: "item",
        url: "/upload-forcast-inventory",
      },
      {
        id: "Add New Brand",
        title: <FormattedMessage id="Add New Brand" />,
        type: "item",
        url: "/addbrand",
      },
      {
        id: "Download Orders Data",
        title: <FormattedMessage id="Download Orders Data" />,
        type: "item",
        url: "/downloadorderdata",
      },
      {
        id: "Bulk Order Detail",
        title: <FormattedMessage id="Bulk Order Detail" />,
        type: "item",
        url: "/bulkorderdetail",
      },
      {
        id: "Add Rewards",
        title: <FormattedMessage id="Add Rewards" />,
        type: "item",
        url: "/add-reward",
      },
      {
        id: "ReconcileReport",
        title: <FormattedMessage id="Cash Reconcile Report" />,
        type: "item",
        url: "/financialreport/ReconcileReport",
      },
      {
        id: "VendorCreation",
        title: <FormattedMessage id="Create Vendor" />,
        type: "item",
        url: "/createvendor",
      },
      {
        id: "POProcess",
        title: <FormattedMessage id="PO Process" />,
        type: "item",
        url: "/poprocess",
      },
      {
        id: "POReport",
        title: <FormattedMessage id="PO Report" />,
        type: "item",
        url: "/po-report",
      },
      {
        id: "POSkuList",
        title: <FormattedMessage id="PO SKU List" />,
        type: "item",
        url: "/po-sku-list",
      },
      {
        id: "InventoryLedger",
        title: <FormattedMessage id="Inventory Ledger" />,
        type: "item",
        url: "/inventory-ledger",
      },
      {
        id: "SlowMoving",
        title: <FormattedMessage id="Slow Moving Quantity" />,
        type: "item",
        url: "/slow-moving-quantity",
      },
      {
        id: "Upload RTO File",
        title: <FormattedMessage id="Upload RTO File" />,
        type: "item",
        url: "/uploadrtofile",
      },
      {
        id: "CoinsReport",
        title: <FormattedMessage id="Coin's Report" />,
        type: "item",
        url: "/coins-report",
      },
      {
        id: "Split Orders",
        title: <FormattedMessage id="Split Orders" />,
        type: "item",
        url: "/split-orders",
      },
      {
        id: "Brandwise Product Data",
        title: <FormattedMessage id="Brandwise Product Data" />,
        type: "item",
        url: "/brandwise-product-data",
      },
      {
        id: "Out Of Stock Products",
        title: <FormattedMessage id="Out Of Stock Products" />,
        type: "item",
        url: "/outofstockproducts",
      },
      {
        id: "Payment Method Changes History",
        title: <FormattedMessage id="Payment Method Changes History" />,
        type: "item",
        url: "/payment-method-changes-history",
      },
      {
        id: "RTO Orders after Payment Mode Change",
        title: <FormattedMessage id="RTO Orders after Payment Mode Change" />,
        type: "item",
        url: "/rto-orders-after-payment",
      },

    ]
  }
  else if ( ['9811068834','8076096127','8448300516','9177139113','9719836187','8750751510'].includes(phoneNumber)) {
    inventory = [
      {
        id: "sellerproductlist",
        title: <FormattedMessage id="Seller Product" />,
        type: "item",
        url: "/sellerproductlist",
      },
      {
        id: "addproduct",
        title: <FormattedMessage id="Add Product" />,
        type: "item",
        url: "/addproduct",
      },

      {
        id: "combopack",
        title: <FormattedMessage id="Add Combo Pack" />,
        type: "item",
        url: "/combopack",
      },
      {
        id: "Add Shades and Size",
        title: <FormattedMessage id="Add Shades and Size" />,
        type: "item",
        url: "/add-shades-and-size",
      },

      {
        id: "Upload Inventory",
        title: <FormattedMessage id="Upload Inventory" />,
        type: "item",
        url: "/uploadinventory",
      },
      {
        id: "Upload Forcast Inventory",
        title: <FormattedMessage id="Upload Forcast Inventory" />,
        type: "item",
        url: "/upload-forcast-inventory",
      },
      {
        id: "Add New Brand",
        title: <FormattedMessage id="Add New Brand" />,
        type: "item",
        url: "/addbrand",
      },
      {
        id: "Download Orders Data",
        title: <FormattedMessage id="Download Orders Data" />,
        type: "item",
        url: "/downloadorderdata",
      },
      {
        id: "Bulk Order Detail",
        title: <FormattedMessage id="Bulk Order Detail" />,
        type: "item",
        url: "/bulkorderdetail",
      },
      {
        id: "Add Rewards",
        title: <FormattedMessage id="Add Rewards" />,
        type: "item",
        url: "/add-reward",
      },
      {
        id: "ReconcileReport",
        title: <FormattedMessage id="Cash Reconcile Report" />,
        type: "item",
        url: "/financialreport/ReconcileReport",
      },
      {
        id: "VendorCreation",
        title: <FormattedMessage id="Create Vendor" />,
        type: "item",
        url: "/createvendor",
      },
      {
        id: "POProcess",
        title: <FormattedMessage id="PO Process" />,
        type: "item",
        url: "/poprocess",
      },
      {
        id: "POReport",
        title: <FormattedMessage id="PO Report" />,
        type: "item",
        url: "/po-report",
      },
      {
        id: "POSkuList",
        title: <FormattedMessage id="PO SKU List" />,
        type: "item",
        url: "/po-sku-list",
      },
      {
        id: "InventoryLedger",
        title: <FormattedMessage id="Inventory Ledger" />,
        type: "item",
        url: "/inventory-ledger",
      },
      {
        id: "SlowMoving",
        title: <FormattedMessage id="Slow Moving Quantity" />,
        type: "item",
        url: "/slow-moving-quantity",
      },
      {
        id: "Upload RTO File",
        title: <FormattedMessage id="Upload RTO File" />,
        type: "item",
        url: "/uploadrtofile",
      },
      {
        id: "CoinsReport",
        title: <FormattedMessage id="Coin's Report" />,
        type: "item",
        url: "/coins-report",
      },
      {
        id: "Split Orders",
        title: <FormattedMessage id="Split Orders" />,
        type: "item",
        url: "/split-orders",
      },
      {
        id: "Brandwise Product Data",
        title: <FormattedMessage id="Brandwise Product Data" />,
        type: "item",
        url: "/brandwise-product-data",
      },
      {
        id: "Out Of Stock Products",
        title: <FormattedMessage id="Out Of Stock Products" />,
        type: "item",
        url: "/outofstockproducts",
      },
      {
        id: "Payment Method Changes History",
        title: <FormattedMessage id="Payment Method Changes History" />,
        type: "item",
        url: "/payment-method-changes-history",
      },
      {
        id: "RTO Orders after Payment Mode Change",
        title: <FormattedMessage id="RTO Orders after Payment Mode Change" />,
        type: "item",
        url: "/rto-orders-after-payment",
      },
      {
        id: "Ship Order Through Excel",
        title: <FormattedMessage id="Ship Order Through Excel" />,
        type: "item",
        url: "/supplychain/ship-order-through-excel",
      },
    ]
  }
  else {
    inventory = [
      {
        id: "sellerproductlist",
        title: <FormattedMessage id="Seller Product" />,
        type: "item",
        url: "/sellerproductlist",
      },
      {
        id: "addproduct",
        title: <FormattedMessage id="Add Product" />,
        type: "item",
        url: "/addproduct",
      },
      // {
      //   id: "allcombopack",
      //   title: <FormattedMessage id="Combo Packs" />,
      //   type: "item",
      //   url: "/all-combopacks",
      // },
      {
        id: "combopack",
        title: <FormattedMessage id="Add Combo Pack" />,
        type: "item",
        url: "/combopack",
      },
      {
        id: "Add Shades and Size",
        title: <FormattedMessage id="Add Shades and Size" />,
        type: "item",
        url: "/add-shades-and-size",
      },
      // {
      //   id: "Upload Inventory",
      //   title: <FormattedMessage id="Upload Inventory" />,
      //   type: "item",
      //   url: "/uploadinventory",
      // },
      {
        id: "Add New Brand",
        title: <FormattedMessage id="Add New Brand" />,
        type: "item",
        url: "/addbrand",
      },
      {
        id: "Download Orders Data",
        title: <FormattedMessage id="Download Orders Data" />,
        type: "item",
        url: "/downloadorderdata",
      },
      {
        id: "Bulk Order Detail",
        title: <FormattedMessage id="Bulk Order Detail" />,
        type: "item",
        url: "/bulkorderdetail",
      },
      {
        id: "Add Rewards",
        title: <FormattedMessage id="Add Rewards" />,
        type: "item",
        url: "/add-reward",
      },
      {
        id: "ReconcileReport",
        title: <FormattedMessage id="Cash Reconcile Report" />,
        type: "item",
        url: "/financialreport/ReconcileReport",
      },
      {
        id: "VendorCreation",
        title: <FormattedMessage id="Create Vendor" />,
        type: "item",
        url: "/createvendor",
      },
      {
        id: "POProcess",
        title: <FormattedMessage id="PO Process" />,
        type: "item",
        url: "/poprocess",
      },
      {
        id: "POReport",
        title: <FormattedMessage id="PO Report" />,
        type: "item",
        url: "/po-report",
      },
      {
        id: "POSkuList",
        title: <FormattedMessage id="PO SKU List" />,
        type: "item",
        url: "/po-sku-list",
      },
      {
        id: "InventoryLedger",
        title: <FormattedMessage id="Inventory Ledger" />,
        type: "item",
        url: "/inventory-ledger",
      },
      {
        id: "SlowMoving",
        title: <FormattedMessage id="Slow Moving Quantity" />,
        type: "item",
        url: "/slow-moving-quantity",
      },
      {
        id: "Upload RTO File",
        title: <FormattedMessage id="Upload RTO File" />,
        type: "item",
        url: "/uploadrtofile",
      },
      {
        id: "CoinsReport",
        title: <FormattedMessage id="Coin's Report" />,
        type: "item",
        url: "/coins-report",
      },
      {
        id: "Split Orders",
        title: <FormattedMessage id="Split Orders" />,
        type: "item",
        url: "/split-orders",
      },
      {
        id: "Brandwise Product Data",
        title: <FormattedMessage id="Brandwise Product Data" />,
        type: "item",
        url: "/brandwise-product-data",
      },
      {
        id: "Out Of Stock Products",
        title: <FormattedMessage id="Out Of Stock Products" />,
        type: "item",
        url: "/outofstockproducts",
      }


    ]
    if ((Number(phoneNumber) === 9315481432)) {
      inventory.push({
        id: "Create Replacement Order",
        title: <FormattedMessage id="Create Replacement Order" />,
        type: "item",
        url: "/create-replacement-order",
      })
    }
  }

  if (accessNumbers.includes(phoneNumber)) {
    inventory.push({
      id: "multiplyOrder",
      title: <FormattedMessage id="Multiply Order" />,
      type: "item",
      url: "/multiply-order",
    })
  }
  if (accessNumbers2.includes(phoneNumber)) {
    inventory.push({
      id: "Return Pickup Order",
      title: <FormattedMessage id="Return Pickup Order" />,
      type: "item",
      url: "/return-pickup-order",
    })
  }
  if (bulkMarkAccess?.includes(phoneNumber)) {
    inventory.push({
      id: "Mark Bulk Order",
      title: <FormattedMessage id="Mark Bulk Order" />,
      type: "item",
      url: "/mark-bulk-order",
    })
  }
  if (priortiyPanel.includes(phoneNumber)) {
    priortizeOrder = [{
      id: "Priortize-florida-order",
      title: <FormattedMessage id="Priortize-florida-order" />,
      type: "item",
      url: "/priortize-order/priortize-florida-order",
    }]
  }
  supplyChain = [
    {
      id: "Dashboard",
      title: <FormattedMessage id="Dashboard" />,
      type: "item",
      url: "/supplychain/dashboard",
    },
    {
      id: "Orders With Issues",
      title: <FormattedMessage id="Orders With Issues" />,
      type: "item",
      url: "/supplychain/orders-with-issues",
    },
    {
      id: "Rto with Less Delivery Attempts",
      title: <FormattedMessage id="Rto with Less Delivery Attempts" />,
      type: "item",
      url: "/supplychain/rto-with-less-attempts",
    },
    {
      id: "InTransit GT(>) 10 Days",
      title: <FormattedMessage id="InTransit GT(>) 10 Days" />,
      type: "item",
      url: "/supplychain/in-transit-gt10-days",
    },
    {
      id: "Delivered Order Feedback",
      title: <FormattedMessage id="Delivered Order Feedback" />,
      type: "item",
      url: "/supplychain/delivered-order-feedback",
    },
    {
      id: "Upload Bulk Order",
      title: <FormattedMessage id="Upload Bulk Order" />,
      type: "item",
      url: "/supplychain/upload-bulk-order",
      breadcrumbs: true,
    },
    {
      id: "Ship Order Through Excel",
      title: <FormattedMessage id="Ship Order Through Excel" />,
      type: "item",
      url: "/supplychain/ship-order-through-excel",
    },
    {
      id: "Create Payment Link",
      title: <FormattedMessage id="Create Payment Link" />,
      type: "item",
      url: "/supplychain/createpaymentlink",
    },
    

  ]

}
else if (role == "3") {
  inventory = [
    {
      id: "sellerproductlist",
      title: <FormattedMessage id="Seller Product" />,
      type: "item",
      url: "/sellerproductlist",
    },
    {
      id: "Download Orders Data",
      title: <FormattedMessage id="Download Orders Data" />,
      type: "item",
      url: "/downloadorderdata",
    },
    {
      id: "Bulk Order Detail",
      title: <FormattedMessage id="Bulk Order Detail" />,
      type: "item",
      url: "/bulkorderdetail",
    },
    {
      id: "POProcess",
      title: <FormattedMessage id="PO Process" />,
      type: "item",
      url: "/poprocess",
    },
    {
      id: "POReport",
      title: <FormattedMessage id="PO Report" />,
      type: "item",
      url: "/po-report",
    },
    {
      id: "CoinsReport",
      title: <FormattedMessage id="Coin's Report" />,
      type: "item",
      url: "/coins-report",
    },
    {
      id: "InventoryLedger",
      title: <FormattedMessage id="Inventory Ledger" />,
      type: "item",
      url: "/inventory-ledger",
    },
    {
      id: "POSkuList",
      title: <FormattedMessage id="PO SKU List" />,
      type: "item",
      url: "/po-sku-list",
    },
  ]
  if ((Number(phoneNumber) === 9910109612)) {
    inventory.push({
      id: "POSkuList",
      title: <FormattedMessage id="PO SKU List" />,
      type: "item",
      url: "/po-sku-list",
    })
  }
}


else if (role == "14" || role == "17") {
  inventory = [
    {
      id: "sellerproductlist",
      title: <FormattedMessage id="Seller Product" />,
      type: "item",
      url: "/sellerproductlist",
    },
    {
      id: "Download Orders Data",
      title: <FormattedMessage id="Download Orders Data" />,
      type: "item",
      url: "/downloadorderdata",
    }
  ]
  supplyChain = [
    {
      id: "Delivered Order Feedback",
      title: <FormattedMessage id="Delivered Order Feedback" />,
      type: "item",
      url: "/supplychain/delivered-order-feedback",
    },
  ]

  if (accessNumbers.includes(phoneNumber)) {
    inventory.push({
      id: "multiplyOrder",
      title: <FormattedMessage id="Multiply Order" />,
      type: "item",
      url: "/multiply-order",
    })
  }
}
else if (role == "18" || role == '13' || role == "14" || phoneNumber == "8287914978") {
  inventory = [
    {
      id: "sellerproductlist",
      title: <FormattedMessage id="Seller Product" />,
      type: "item",
      url: "/sellerproductlist",
    },

  ]

  supplyChain = [
    {
      id: "Delivered Order Feedback",
      title: <FormattedMessage id="Delivered Order Feedback" />,
      type: "item",
      url: "/supplychain/delivered-order-feedback",
    },
  ]
}
else if (role == '5') {
  inventory = [
    {
      id: "sellerproductlist",
      title: <FormattedMessage id="Seller Product" />,
      type: "item",
      url: "/sellerproductlist",
    },
    {
      id: "Product Forecast",
      title: <FormattedMessage id="Product Forecast" />,
      type: "item",
      url: "/productforcast",
    },

  ]
  supplyChain = [
    {
      id: "Delivered Order Feedback",
      title: <FormattedMessage id="Delivered Order Feedback" />,
      type: "item",
      url: "/supplychain/delivered-order-feedback",
    },
  ]

}
else if (phoneNumber=='7022888630' || phoneNumber=='8595198905' || phoneNumber=='7428627261') {
  inventory = [
    {
      id: "sellerproductlist",
      title: <FormattedMessage id="Seller Product" />,
      type: "item",
      url: "/sellerproductlist",
    },

  ]

}







export const Product = {
  id: "AllProduct",
  title: <FormattedMessage id="Product" />,
  type: "group",
  children: [
    {
      id: "Inventory",
      title: <FormattedMessage id="Inventory" />,
      type: "collapse",
      icon: icons["IconBuildingStore"],
      children: inventory
    },
    {
      id: "SupplyChain",
      title: <FormattedMessage id="Supply Chain" />,
      type: "collapse",
      icon: icons["IconBuildingStore"],
      children: supplyChain
    },
    {
      id: "Priortize Order On Florida",
      title: <FormattedMessage id="Priortize Order On Florida" />,
      type: "collapse",
      icon: icons["IconBuildingStore"],
      children: priortizeOrder
    },

  ],
};
