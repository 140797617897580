import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Box,
  Paper,
} from "@mui/material";
import { MapPin, Phone, Mail, Clock, Calendar, FileText } from "lucide-react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import axios from "axios";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export default function SellerDetails() {
  const { _id, sellerId } = useParams();
  const [sellerProfile, setSellerProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const GOOGLE_MAPS_API_KEY = "AIzaSyDZUXfv2E1crCqQLWkM0LEeRztM3d8g2Sg";

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  // const center =

  const formatTime = (time) => {
    if(!time)return ""
    const hours = time.slice(0, 2);
    const minutes = time.slice(2);
    return `${hours}:${minutes}`;
  };

  const formatDays = (days) => {
    if(!days) return ''
    const daysMap = {
      1: "Mon",
      2: "Tue",
      3: "Wed",
      4: "Thu",
      5: "Fri",
      6: "Sat",
      7: "Sun",
    };
    return days
      .split(",")
      .map((day) => daysMap[day])
      .join(", ");
  };

  const getAllSellers = () => {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_PATH +
          "/ondc/crm/seller/detailsFromCrm?sellerId=" +
          sellerId
      )
      .then((res) => {
        console.log("response of seller list", res.data);
        if (res.data.success) {
          setSellerProfile(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error of seller list", error);
      });
  };

  console.log('sellerProfile?.gps?.coordinates[1]',sellerProfile?.gps?.coordinates[1])

  useEffect(() => {
    getAllSellers();
  }, []);

  return (
    <Paper elevation={0}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Card sx={{ margin: "auto", mt: 4 }}>
          {/* <Box sx={{ position: "relative", height: 200, overflow: "hidden" }}>
            <img
              src={sellerProfile.symbol}
              alt={sellerProfile.name}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box> */}
          <CardContent>
            <Typography gutterBottom variant="h4" component="div">
              {sellerProfile?.name}
            </Typography>
            <Chip
              label={sellerProfile?.isActive ? "Active" : "Inactive"}
              color={sellerProfile?.isActive ? "success" : "error"}
              sx={{ mb: 2 }}
            />
            <Typography variant="body2" color="text.secondary" paragraph>
              {sellerProfile?.shortDescription}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <MapPin size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Address"
                      secondary={`${sellerProfile?.address.street}, ${sellerProfile?.address.locality}, ${sellerProfile?.address.city}, ${sellerProfile?.address.state} - ${sellerProfile?.address.pincode}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Phone size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Phone"
                      secondary={sellerProfile?.phoneNumber}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Mail size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Email"
                      secondary={sellerProfile?.emailId}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <Clock size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Working Hours"
                      secondary={`${formatTime(
                        sellerProfile?.fulfillment.start
                      )} - ${formatTime(sellerProfile?.fulfillment.end)}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Calendar size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Working Days"
                      secondary={formatDays(sellerProfile?.fulfillment.days)}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FileText size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Description"
                      secondary={sellerProfile?.longDescription}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Holidays:
              </Typography>
              {sellerProfile?.fulfillment.holidays.map((holiday, index) => (
                <Chip key={index} label={holiday} sx={{ mr: 1, mb: 1 }} />
              ))}
            </Box>
            <Box sx={{ mt: 2, height: 400 }}>
              <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: sellerProfile?.gps?.coordinates[0],
                    lng: sellerProfile?.gps?.coordinates[1],
                  }}
                  zoom={15}
                >
                  <Marker
                    position={{
                      lat: sellerProfile?.gps?.coordinates[0],
                      lng: sellerProfile?.gps?.coordinates[1],
                    }}
                  />
                </GoogleMap>
              </LoadScript>
            </Box>
          </CardContent>
        </Card>
      )}
    </Paper>
  );
}
