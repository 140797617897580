import React, { useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { Typography } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";

function ProductForecast() {
  const [productId, setProductId] = useState("");
  const [loading,setLoading] = useState(false)
  const [data, setData] = useState({});
  const handelSearch = () => {
    setLoading(true)
    axios
      .get(process.env.REACT_APP_PATH+ `/sales/checkForecastById?id=${productId}`)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false)
        } else {
          alert(res.data?.message || "Someting went wrong");
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false)
        alert("Someting went wrong");
      });
  };
  return (
    <SubCard>
      <Grid container alignItems={'center'}>
        <TextField
          value={productId}
          onChange={(e) => setProductId(e.target.value.trim())}
          placeholder="Enter Product id"
          variant="outlined"
          size='medium'

        />
        <LoadingButton sx={{marginLeft:10}} variant='contained' loading={loading} loadingIndicator={'Loading...'} onClick={handelSearch}>Search</LoadingButton>
      </Grid>
      <Grid mt={2}>
        <Grid container alignItems={'center'}>
          <Typography variant='h4' >Name : </Typography>
          <Typography> {data?.name}</Typography>
        </Grid>
        <Grid container alignItems={'center'} mt={1}> 
          <Typography variant='h4'>Day Wise : </Typography>
          <Typography> {data?.dayWise}</Typography>
        </Grid>
      </Grid>
    </SubCard>
  );
}

export default ProductForecast;
