import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import MainCard from "../../ui-component/cards/MainCard";
import SubCard from "../../ui-component/cards/SubCard";
import axios from "axios";
import { Checkbox } from "@mui/material";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

function EditBulkOrderScm() {
  const { OrderId, canApprove, canDispatch } = useParams();
  const router = useHistory();
  const agentRole = getDecryptDataFromLocal("setRole");
  const agentType = localStorage.getItem("agentType");
  const agentAccessRole = ["5"];
  const [loading, setLoading] = useState(true);
  const [editBrandIds, setEditBrandIds] = useState([
    "6539e5f4ccd18dd0dbc4dc79",
    "65447bd2c93023787fedfa7c",
  ]);
  const [mrpChange, setMrpChange] = useState([
    {
      currentMrp: 0,
      reqestMrp: 0,
      checkForApproval: false,
      productId: null,
    },
  ]);

  const [sellerDetails, setSellerDetails] = useState({
    name: "",
    phoneNumber: "",
    address: {},
  });
  const [orderDetails, setOrderDetails] = useState({});
  const [productList, setProductList] = useState([]);
  const [willUpdateOnProduct, setWillUpdateOnProduct] = useState(false);
  const agentRoleFromLocal = localStorage.getItem("agentRole");
  const agentPhoneNumber = getDecryptDataFromLocal("phoneNumber");
  const [btnDisable, setBtnDisable] = useState(false);
  const [keywestQuantity, setKeywestQuantity] = useState([
    {
      quantity: 0,
      productId: null,
    },
  ]);
  const getAvaileableQuantity = async (
    sellerId,
    productId,
    quantity,
    EANCode,
    isShorea=false
  ) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PATH}/products/checkUcInventory`,
        {
          sellerId: sellerId,
          productId: productId,
          quantity: quantity,
          agentRole: agentRoleFromLocal,
          agentPhoneNumber: agentPhoneNumber,
          crmOrder: true,
          EANCode: EANCode,
          isShorea:isShorea

        }
      );
      if (response.data.success) {
        return response.data.data;
      } else return 0;
    } catch (error) {
      return 0;
    }
  };

  const handleMrpChange = (productId, newMrp) => {
    let findIndex = mrpChange.findIndex((item) => item.productId == productId);
    let temp = [...mrpChange];
    if (findIndex > -1) {
      let currentItem = temp[findIndex];
      currentItem.reqestMrp = +newMrp;

      currentItem.checkForApproval =
        newMrp > 0 ? +newMrp < currentItem.currentMrp : false;
      setMrpChange(temp);
    }
  };

  const handleKeywestQuantity = (productId, newQuantity) => {
    let findIndex = keywestQuantity.findIndex(
      (item) => item.productId == productId
    );
    let temp = [...keywestQuantity];
    if (findIndex > -1) {
      let currentItem = temp[findIndex];
      currentItem.quantity = +newQuantity;
    } else {
      temp.push({
        quantity: +newQuantity,
        productId: productId,
      });
    }
    setKeywestQuantity(temp);
  };

  const getOrderProducts = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_PATH}/sales/getBulkOrderById/${OrderId}`)
      .then(async (res) => {
        if (res.data.success) {
          let temp = [];
          let temp2 = [];
          if (res.data.data.products && res.data.data.products.length > 0) {
            for (let i = 0; i < res.data.data.products.length; i++) {
              let proTemp = res.data.data.products[i];
              // let check product is combo or not
              temp2.push({
                currentMrp: proTemp.mrp,
                reqestMrp: 0,
                checkForApproval: false,
                productId: proTemp._id,
              });
              let limit = await getAvaileableQuantity(
                res.data.data.sellerId,
                proTemp._id,
                proTemp.quantity,
                proTemp?.EANCode
              );
              let onlineLimit  =  await getAvaileableQuantity(
                res.data.data.sellerId,
                proTemp._id,
                proTemp.quantity,
                proTemp?.EANCode,
                true
              );

              proTemp.availableQuantity = limit;
              proTemp.onlineLimit = onlineLimit
              proTemp.pendingQuantity = (res.data.data?.totalPayableAmount<=10000 && (agentPhoneNumber!='9873804039'||agentPhoneNumber!='8969708515'))?0: limit >= proTemp.quantity ? 0 : proTemp.quantity - limit;
              proTemp.dispatchQuantity = (res.data.data?.totalPayableAmount<=10000 && (agentPhoneNumber!='9873804039'||agentPhoneNumber!='8969708515'))?proTemp.quantity  : limit >= proTemp.quantity ? proTemp.quantity : limit;

              temp.push(proTemp);
            }
            setMrpChange(temp2);
            setOrderDetails(res.data.data);
            setProductList(temp);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log("error of get product", error);
      });
  };

  const changeDispatchQuantity = (productId, value) => {
    value = Number(value);

    if (typeof value == "number") {
      let allProducts = [...productList];
      allProducts.forEach((item) => {
        if (item.productId == productId) {
          item.dispatchQuantity =
          value <= item.quantity
            ? value <= item.availableQuantity
              ? value
              : item.quantity - item?.availableQuantity <=
                item.availableQuantity
              ? item.quantity - item?.availableQuantity
              : item.availableQuantity
            : item.quantity;
        item.pendingQuantity =
          item.quantity -
          (value <= item.quantity
            ? value <= item.availableQuantity
              ? value
              : item.quantity - item?.availableQuantity <=
                item.availableQuantity
              ? item.quantity - item?.availableQuantity
              : item.availableQuantity
            : item.quantity);
        }
      });
      setProductList(allProducts);
    }
  };
  const changeRemark = (productId, value) => {
    let allProducts = [...productList];
    allProducts.forEach((item) => {
      if (item.productId == productId) {
        item.remark = value;
      }
    });
    setProductList(allProducts);
  };

  const createOrder = () => {
    setBtnDisable(true);
    let approveProducts = [];
    let pendingProducts = [];
    productList.forEach((pro) => {
      if (pro.pendingQuantity == 0) {
        approveProducts.push(pro);
      } else {
        if (pro.dispatchQuantity > 0) {
          approveProducts.push({
            ...pro,
            quantity: pro.dispatchQuantity,
          });
        }
        pendingProducts.push({
          ...pro,
          quantity: pro.pendingQuantity,
        });
      }
    });
    approveProducts.unshift("approved");

    if (pendingProducts.length > 0) {
      pendingProducts.unshift("pending");
    }

    if (pendingProducts.length > 0) {
      axios
        .post(`${process.env.REACT_APP_PATH}/sales/createAndSplitBulkOrders`, {
          orders: [approveProducts, pendingProducts],
          mainOrderId: OrderId,
          needToApprove: true,
        })
        .then((res) => {
          console.log("res of spilt", res.data);
          if (res.data.success) {
            alert(res.data?.message ||"Order split successfully");
            router.goBack();
          } else {
            alert(res.data?.message || "someting went wrong");
            setBtnDisable(false);
          }
        })
        .catch((error) => {
          console.log("error ", error);
          alert("someting went wrong");
          setBtnDisable(false);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_PATH}/sales/approveOrAddQuantity`, {
          orderId: OrderId,
          action: "approved", // notApproved
        })
        .then((res) => {
          console.log("res of approve ", res.data);
          if (res.data.success) {
            alert(res.data?.message ||"Order Approved successfully");
            router.goBack();
          } else {
            alert(res.data?.message || "someting went wrong");
            setBtnDisable(false);
          }
        })
        .catch((error) => {
          console.log("error of approve", error);
          alert("someting went wrong");
          setBtnDisable(false);
        });
    }
  };
  const createOrderForChild = () => {
    setBtnDisable(true);
    let approveProducts = [];
    let pendingProducts = [];
    productList.forEach((pro) => {
      if (pro.pendingQuantity == 0) {
        approveProducts.push(pro);
      } else {
        if (pro.dispatchQuantity > 0) {
          approveProducts.push({
            ...pro,
            quantity: pro.dispatchQuantity,
          });
        }
        pendingProducts.push({
          ...pro,
          quantity: pro.pendingQuantity,
        });
      }
    });
    approveProducts.unshift("approved");

    if (pendingProducts.length > 0) {
      pendingProducts.unshift("pending");
    }

    if (pendingProducts.length > 0) {
      axios
        .post(`${process.env.REACT_APP_PATH}/sales/splitBulkChildOrders`, {
          orders: [approveProducts, pendingProducts],
          mainOrderId: OrderId,
          needToApprove: true,
        })
        .then((res) => {
          console.log("res of spilt", res.data);
          if (res.data.success) {
            alert(res.data?.message||"Order split successfully");
            router.goBack();
          } else {
            alert(res.data?.message || "someting went wrong");
            setBtnDisable(false);
          }
        })
        .catch((error) => {
          console.log("error ", error);
          alert("someting went wrong");
          setBtnDisable(false);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_PATH}/sales/approveOrAddQuantity`, {
          orderId: OrderId,
          action: "approved", // notApproved
        })
        .then((res) => {
          console.log("res of approve ", res.data);
          if (res.data.success) {
            alert(res.data?.message||"Order Approved successfully");
            router.goBack();
          } else {
            alert(res.data?.message || "someting went wrong");
            setBtnDisable(false);
          }
        })
        .catch((error) => {
          console.log("error of approve", error);
          alert("someting went wrong");
          setBtnDisable(false);
        });
    }
  };
  const cancelOrder = () => {
    setBtnDisable(true);
    axios
      .post(`${process.env.REACT_APP_PATH}/products/cancelCrmBulkOrder`, {
        orderId: OrderId,
      })
      .then((res) => {
        console.log("res of cancel", res.data);
        if (res.data.success) {
          alert(res.data?.message || "Order canceled successfully");
          router.goBack();
        } else {
          alert(res.data?.message || "someting went wrong");
          setBtnDisable(false);
        }
      })
      .catch((error) => {
        console.log("error ", error);
        alert("someting went wrong");
        setBtnDisable(false);
      });
  };

  const approveByAgent = () => {
    axios
      .post(`${process.env.REACT_APP_PATH}/sales/approveOrAddQuantity`, {
        orderId: OrderId,
        action: "approved", // notApproved
      })
      .then((res) => {
        console.log("res of approve ", res.data);
        if (res.data.success) {
          alert(res.data?.message || "Order approved successfully");
          
          router.goBack();
        } else {
          alert(res.data?.message || "someting went wrong");
        }
      })
      .catch((error) => {
        console.log("error of approve", error);
        alert("Someting went wrong");
      });
  };

  const rejectOrder = () => {
    axios
      .post(`${process.env.REACT_APP_PATH}/sales/approveOrAddQuantity`, {
        orderId: OrderId,
        action: "notApproved", // notApproved
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data?.message || "Order reject successfully");
          router.goBack();
        } else {
          alert(res.data?.message || "someting went wrong");
        }
        console.log("res of approve ", res.data);
      })
      .catch((error) => {
        console.log("error of approve", error);
        alert("Someting went wrong");
      });
  };

  const productMrpUpdate = () => {
    let reqestProductChange = mrpChange.filter((item) => item.reqestMrp > 0);
    let modifiedArray = reqestProductChange.map((item) => ({
      productId: item.productId,
      mrp: item.reqestMrp,
    }));
    console.log("modifiedArray", {
      orderId: OrderId,
      products: modifiedArray,
      mrpWillChangeInSystem: willUpdateOnProduct,
    });
    axios
      .post(process.env.REACT_APP_PATH + "/products/changeMrpAndUpdateOrder", {
        orderId: OrderId,
        products: modifiedArray,
        mrpWillChangeInSystem: willUpdateOnProduct,
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data.message || "Mrp Changed");
          getOrderProducts();
        } else {
          alert(res.data.message || "someting went wrong");
        }
      })
      .catch((error) => {
        alert("someting went wrong");
      });
  };

  const updateKeywestQuantityInOrder = () => {
    let keywestQuantityArray = keywestQuantity.filter(
      (item) => item.quantity > 0
    );
    console.log("keywestQuantityArray", {
      orderId: OrderId,
      keywest: keywestQuantityArray,
    });
    if (keywestQuantityArray.length > 0) {
      axios
        .post(
          process.env.REACT_APP_PATH + "/products/editKeywestInCrmBulkOrder",
          {
            orderId: OrderId,
            keywest: keywestQuantityArray,
          }
        )
        .then((res) => {
          if (res.data.success) {
            alert(res.data.message || "Quantity Updated");
            getOrderProducts();
          } else {
            alert(res.data.message || "someting went wrong");
          }
        })
        .catch((error) => {
          alert("someting went wrong");
        });
    } else {
      alert("Please add quantity");
    }
  };

  useEffect(() => {
    getOrderProducts();
  }, []);

  if (loading) {
    return (
      <div style={{ backgroundColor: "rgba(0,0,0,.4)", height: "100vh" }}>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <Grid>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item md={3} lg={3} sm={6} xs={12}>
            <TextField
              name="Parent Order Id"
              value={orderDetails?.parentBulkNumericOrderId}
              label="Parent Order Id"
              fullWidth
            />
          </Grid>
          <Grid item md={3} lg={3} sm={6} xs={12}>
            <TextField
              name="numericOrder"
              value={orderDetails?.numericOrderId}
              label="Order Id"
              fullWidth
            />
          </Grid>
          <Grid item md={3} lg={3} sm={6} xs={12}>
            <TextField
              name="Name"
              value={orderDetails?.name}
              label="Name"
              fullWidth
            />
          </Grid>
          <Grid item md={3} lg={3} sm={6} xs={12}>
            <TextField
              name="phoneNumber"
              value={orderDetails?.phoneNumber}
              label="Phone Number"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item md={9} lg={9} sm={12} xs={12}>
            <SubCard
              title={"Sku in order"}
              secondary={
                <>
                  {mrpChange.some((item) => item.checkForApproval == true) ? (
                    (agentPhoneNumber == "9891774071" ||
                      agentPhoneNumber == "9719836187") && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginRight: 10,
                          }}
                        >
                          <Checkbox
                            checked={willUpdateOnProduct}
                            onChange={(e) =>
                              setWillUpdateOnProduct(e.target.checked)
                            }
                          />
                          <p> Update mrp on products</p>
                        </div>
                        <Button onClick={productMrpUpdate} variant="contained">
                          Product Update
                        </Button>
                      </div>
                    )
                  ) : (agentRole == "4" || agentPhoneNumber == "9719836187") &&
                    mrpChange.some((item) => item.reqestMrp > 0) ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginRight: 10,
                        }}
                      >
                        <Checkbox
                          checked={willUpdateOnProduct}
                          onChange={(e) =>
                            setWillUpdateOnProduct(e.target.checked)
                          }
                        />
                        <p> Update mrp on products</p>
                      </div>
                      <Button variant="contained" onClick={productMrpUpdate}>
                        Product Update
                      </Button>
                    </div>
                  ) : null}
                </>
              }
            >
              <Table style={{ width: "100%" }}>
                <TableContainer style={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell>MRP</TableCell>
                      {(["4"].includes(agentRole) ||
                        ["9719836187"].includes(agentPhoneNumber)) && (
                        <TableCell>Request MRP</TableCell>
                      )}

                      <TableCell>Quantity</TableCell>
                      {agentRole == 5 && <TableCell>New Quanitity</TableCell>}
                      <TableCell>Available Quantity</TableCell>
                      <TableCell>Online Quantity</TableCell>
                      <TableCell>Case Size</TableCell>
                      <TableCell>Dispatch Quantity</TableCell>
                      <TableCell>Pending Quantity</TableCell>
                      <TableCell sx={{ width: 200 }}>Remark</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productList &&
                      productList.map((product) => (
                        <TableRow>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>
                            {product?.mrp || product.menuPrice}
                          </TableCell>
                          {(["4"].includes(agentRole) ||
                            ["9719836187",'7052950504'].includes(agentPhoneNumber)) && (
                            <TableCell>
                              <TextField
                                disabled={
                                  (["4"].includes(agentRole) ||
                                    ["9719836187",'7052950504'].includes(
                                      agentPhoneNumber
                                    )) &&
                                  canDispatch == "true"
                                    ? false
                                    : true
                                }
                                value={
                                  mrpChange.filter(
                                    (item) =>
                                      item.productId == product.productId
                                  )[0].reqestMrp
                                }
                                onChange={(e) =>
                                  handleMrpChange(
                                    product.productId,
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                          )}

                          <TableCell>{product.quantity}</TableCell>
                        

                          {agentRole == 5 &&
                            editBrandIds.includes(product.brandId) && (
                              <TableCell>
                                <TextField
                                  value={
                                    keywestQuantity.filter(
                                      (item) =>
                                        item.productId == product.productId
                                    )[0]?.quantity || 0
                                  }
                                  onChange={(e) =>
                                    handleKeywestQuantity(
                                      product.productId,
                                      e.target.value
                                    )
                                  }
                                  label="New Quantity"
                                  fullWidth
                                />
                              </TableCell>
                            )}
                          <TableCell>{product.availableQuantity}</TableCell>
                          <TableCell>{product.onlineLimit}</TableCell>
                          <TableCell>{product?.caseSize}</TableCell>
                          <TableCell>
                            <TextField
                              disabled={
                                (["4"].includes(agentRole) ||
                                  ["9719836187",'7052950504'].includes(agentPhoneNumber)) &&
                                canDispatch == "true"
                                  ? false
                                  : true
                              }
                              value={product.dispatchQuantity}
                              onChange={(e) =>
                                changeDispatchQuantity(
                                  product.productId,
                                  e.target.value,
                                  product
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>{product.pendingQuantity}</TableCell>
                          <TableCell>
                            <TextField
                              disabled={
                                (["4"].includes(agentRole) ||
                                  ["9719836187",'7052950504'].includes(agentPhoneNumber)) &&
                                canDispatch == "true"
                                  ? false
                                  : true
                              }
                              value={product.remark}
                              onChange={(e) =>
                                changeRemark(product.productId, e.target.value)
                              }
                              title="Remark"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </TableContainer>
              </Table>
            </SubCard>
          </Grid>
          <Grid item md={3} lg={3} xs={12} sm={12}>
            <SubCard title="Delivery address" sx={{ position: "relative" }}>
              <div style={{ maxHeight: 200, overflow: "scroll" }}>
                <Typography>Name : {orderDetails?.address?.name}</Typography>
                <Typography>
                  phone Number : {orderDetails?.address?.phoneNumber}
                </Typography>
                <Typography>
                  Address 1 : {orderDetails?.address?.address1}
                </Typography>
                <Typography>
                  Address 2 : {orderDetails?.address?.address2}
                </Typography>
                <Typography>
                  Pincode : {orderDetails?.address?.postalCode}
                </Typography>
              </div>
            </SubCard>
            <SubCard title={"price Details"} sx={{ marginTop: 5 }}>
              <Grid xs={12} container justifyContent={"space-between"}>
                <Typography align="left" variant="subtitle1">
                  {" "}
                  Grand Total :{" "}
                </Typography>

                <Typography align="left" variant="subtitle1">
                  {" "}
                  &#8377; {orderDetails?.totalPayableAmount}{" "}
                </Typography>
              </Grid>
              <Grid xs={12} container justifyContent={"space-between"}>
                <Typography align="left" variant="subtitle1">
                  {" "}
                  Shipping Cost :{" "}
                </Typography>

                <Typography align="left" variant="subtitle1">
                  {" "}
                  &#8377; {orderDetails?.shippingCost||0}{" "}
                </Typography>
              </Grid>
              {agentRole != 5 && (
                <Grid xs={12} container justifyContent={"space-between"}>
                  <Typography align="left" variant="subtitle1">
                    {" "}
                    Dispatch Order Amount :{" "}
                  </Typography>

                  <Typography align="left" variant="subtitle1">
                    {" "}
                    &#8377;{" "}
                    {Math.ceil(
                      productList.reduce(
                        (acc, item) =>
                          (acc += item.dispatchQuantity * item.price),
                        0
                      )
                    )}{" "}
                  </Typography>
                </Grid>
              )}
            </SubCard>

            {canApprove == "true" ? (
              <>
                {agentRole == 5 && (
                  <Button
                    onClick={updateKeywestQuantityInOrder}
                    variant="contained"
                    fullWidth
                    style={{ marginTop: 10 }}
                    color="secondary"
                  >
                    Update Quanitity
                  </Button>
                )}
                {agentRole == 4 || ['9719836187','7052950504'].includes(agentPhoneNumber) ? (
                  <>
                    <Button
                      style={{ marginTop: 10 }}
                      variant="contained"
                      fullWidth
                      onClick={orderDetails?.parentBulkNumericOrderId?createOrderForChild:createOrder}
                      disabled={btnDisable || orderDetails?.status !==1 ||Math.ceil(
                        productList.reduce(
                          (acc, item) =>
                            (acc += item.dispatchQuantity * item.price),
                          0
                        )
                      )==0}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  ((agentRole == 5 && agentType == "BULK") ||
                    agentRole == 14 ||
                    agentRole == 13|| agentPhoneNumber=='7532035127'||agentPhoneNumber=='8826956978') && (
                    <Grid container spacing={2} mt={2}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={rejectOrder}
                          style={{
                            backgroundColor: "red",
                          }}
                        >
                          Reject
                        </Button>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={approveByAgent}
                          disabled={orderDetails?.status !==1}
                        >
                          Approve
                        </Button>
                      </Grid>
                    </Grid>
                  )
                )}
              </>
            ) : null}
            <>
              {(['8448300516','9719836187','8178277221','8750751510'].includes(agentPhoneNumber) ||agentRole == 4) && canApprove == "true" 
                ? orderDetails?.status == 1 &&
                  (orderDetails?.orderStage ==1||orderDetails?.orderStage==3)  && (
                      <Button
                        style={{ marginTop: 10, backgroundColor: "red" }}
                        variant="contained"
                        fullWidth
                        onClick={cancelOrder}
                        disabled={btnDisable}
                        
                      >
                        Cancel
                      </Button>
                    )
                : null}
            </>
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}

export default EditBulkOrderScm;
