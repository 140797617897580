import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

function AlertModal({ visible, onClose, okPress, orderId,isZohoInvoce=false }) {
  const [disableBtn, setDisableBtn] = useState(false);
  const accessButton = ["9719836187", "8826193896", "8800970879",'9891774071','7052950504','9717315796'];
  const phoneNumber = getDecryptDataFromLocal("phoneNumber");
  const shipOnPorter = (e) => {
    setDisableBtn(true);
    let url = `${process.env.REACT_APP_PATH}/products/shipBulkOrderFromRocketBox`;
    const dataObj = {
      orderId: orderId,
      isrocketOrPorter: false,
    };
    // console.log('mooimlk',dataObj)
    axios({
      method: "POST",
      url: url,
      data: dataObj,
    })
      .then((res) => {
        if (res.data.success) {
          setDisableBtn(false);
          res?.data?.data?.labelUrl &&
            window.open(res?.data?.data?.labelUrl, "_blank");

          alert("orders shipped successful");
          window.location.reload();
        } else {
          alert(res.data.message);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        let error = err?.response?.data?.message || "";

        if (error == "") {
          for (let key in err?.response?.data?.message) {
            if (err?.response?.data?.message?.hasOwnProperty(key)) {
              error = err?.response?.data?.message[key];
              break;
            }
          }

          alert(error);
        }
        setDisableBtn(false);
      });
  };

  const SubmitOnRocketBox = (e) => {
    setDisableBtn(true);
    // let url = bulkOrder?`${process.env.REACT_APP_PATH}/products/shipBulkOrderFromUc` : openShipmentPopup?.type == 'unicommerce' ? `${process.env.REACT_APP_PATH}/products/shipOrderFromUc` : `${process.env.REACT_APP_PATH}/products/shipFloridaOrder`
    let url = null;
    url = `${process.env.REACT_APP_PATH}/products/shipBulkOrderFromRocketBox`;

    const dataObj = {
      orderId: orderId,
      isrocketOrPorter: true,
      packagingUnitDetails:[]
    };
    axios({
      method: "POST",
      url: url,
      data: dataObj,
    })
      .then((res) => {
        if (res.data.success) {
          setDisableBtn(false);
          res?.data?.data?.labelUrl &&
            window.open(res?.data?.data?.labelUrl, "_blank");
          alert("orders shipped successful");
          window.location.reload();
        } else {
          let error = res.data.message || "";
            alert(error);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        let error = err?.response?.data?.message || "";
        if (openShipmentPopup?.type == "unicommerce") {
          alert(error);
          window.location.reload();
        } else {
          if (error == "") {
            for (let key in err?.response?.data?.message) {
              if (err?.response?.data?.message?.hasOwnProperty(key)) {
                error = err?.response?.data?.message[key];
                break;
              }
            }
          }
          alert(error);
        }
        setDisableBtn(false);
      });
  };

  return (
    <Dialog
      open={visible}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogTitle id="alert-dialog-title">
          <Grid container>
            <Grid item lg={10}>
              <Typography variant="h2">Select delivery partner</Typography>
            </Grid>
            <Grid item lg={2} justifyContent={"flex-end"} display={"flex"}>
              <Button variant="outlined" onClick={onClose}>
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <Grid container>
          <Grid item lg={12}>
            <Divider />
          </Grid>
        </Grid>
        {accessButton.includes(phoneNumber) && (
          <Grid container mt={2}>
            <Grid item lg={8} md={8} sm={12}>
              <Grid>
                <Button
                  variant="contained"
                  onClick={shipOnPorter}
                  disabled={disableBtn}
                >
                  Porter
                </Button>
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <Grid>
                <Button
                  disabled={disableBtn}
                  color="primary"
                  variant="contained"
                  onClick={isZohoInvoce?okPress:SubmitOnRocketBox }
                >
                  Rocket Box
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AlertModal;
