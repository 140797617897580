import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconBuildingStore,
  IconTrendingUp
} from "@tabler/icons";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconBuildingStore: IconBuildingStore,
  IconTrendingUp:IconTrendingUp
};

const bulkAccess = ['7001029850','9719836187','9667530528','9891774071','8374454823','8178277221','9268096291','8826956978','8126040893','8279461474','8750022180','9873804039','7982526137','8750751510','9811068834','7503080335','9717315796','8969708515','9693813591','7052950504','8076096127','8294360196','9990619120']

const agentRole = getDecryptDataFromLocal("setRole");
const agentType = localStorage.getItem("agentType");

const bulkOrderCreate=[...bulkAccess,'9911579990','9315405080','8448300516','9319132363','9990359945','9643408732','9990359945','9911579990','7982526137']
const cashBulkOrderCreate=[...bulkAccess,'9911579990','9315405080','8448300516','9319132363','9990359945','9911579990'].filter((item)=>item!=='8178277221','7982526137')
const bulkOrderApproval = [...bulkAccess,'9911579990','9315405080','8800970879','8826193896','7701974309','8383908752','8448300516','7532035127','9319132363','9643408732','9990359945','9911579990','7982526137']
const bulkOrderList = [...bulkAccess,'9873380793','8700175494','8800970879','8826193896','8448300516','9560128741','7532035127','9319132363']
const bulkShipOrder = [...bulkAccess,'9873380793','8700175494','9911579990','9315405080','8800970879','8826193896','7701974309','8383908752','8448300516','9560128741','7532035127','9319132363','9911579990','7982526137','7417448633']

const allBulkAnalysis = ['9667530528','8178277221','8374454823','9719836187','8126040893','8750022180','7982526137','8750751510','9811068834','7503080335','9717315796','9911579990','7982526137','9693813591','7052950504','9990619120','8969708515']
const BulkAnalysis = [...allBulkAnalysis,'9560128741','8448300516','9319132363','8279461474','8076096127','8700175494','7011976531','9891774071']
const bulkOrderMargin  = [...allBulkAnalysis,]
const bulkOrderDonwload = [...allBulkAnalysis,'9560128741','8448300516','9319132363','8279461474','8076096127','8700175494','9891774071']
const bulkProductDownload = [...allBulkAnalysis,'9560128741','8448300516','9319132363','8279461474','8076096127','8700175494','9891774071']
const updateZohoCustomerId = [...allBulkAnalysis]
const addChildSellerAccess = ['9719836187','9891774071','9717315796']




//-----------------------|| Customer MENU ITEMS ||-----------------------//

const phoneNumber = getDecryptDataFromLocal("phoneNumber");
export const Bulk = {
  id: "Bulk",
  title: <FormattedMessage id="Bulk" />,
  type: "group",
  children: [

    {
      id: "Bulk Order",
      title: <FormattedMessage id="Bulk Order" />,
      type: "collapse",
      icon: icons["IconBuildingStore"],
      children: [

    (bulkOrderCreate.includes(phoneNumber)) && 
    {
      id: "Bulk Order Create",
      title: <FormattedMessage id="Bulk Order Create" />,
      type: "item",
      url: "/bulk/ordercreate",
    },

    (cashBulkOrderCreate.includes(phoneNumber)) && 
    {
      id: "Cash Bulk Order Create",
      title: <FormattedMessage id="Cash Bulk Order Create" />,
      type: "item",
      url: "/bulk/cashordercreate",
    },

    (addChildSellerAccess.includes(phoneNumber)) && 
    {
      id: "Add Child Seller",
      title: <FormattedMessage id="Add Child Seller" />,
      type: "item",
      url: "/bulk/addbulkparent",
    },
   ( bulkOrderApproval.includes(phoneNumber) || (agentRole==5&& agentType=='BULK')) &&  {
      id: "Bulk Order Approval by SCM",
      title: <FormattedMessage id="Bulk Order Approval" />,
      type: "item",
      url: "/bulk/bulkorderapprovalforscm",
    },
    (bulkOrderList.includes(phoneNumber)) &&  {
      id: "Bulk Order List",
      title: <FormattedMessage id="Bulk Order List" />,
      type: "item",
      url: "/bulk/bulkorderlist",
    },
    (bulkShipOrder.includes(phoneNumber) || (agentRole==5&& agentType=='BULK') )&&  {
      id: "Bulk Ship Status",
      title: <FormattedMessage id="Bulk Ship Order" />,
      type: "item",
      url: "/bulk/bulkshipstatus",
    },
    
    // bulkAccess.includes(phoneNumber) &&  {
    //   id: "Move Bulk Order",
    //   title: <FormattedMessage id="Move Bulk Order" />,
    //   type: "item",
    //   url: "/bulk/moveBulkOrder",
    // },
  ],

    },
    (BulkAnalysis.includes(phoneNumber)||agentRole=='14') &&  {
      id: "Bulk Order",
      title: <FormattedMessage id="Bulk Analysis" />,
      type: "collapse",
      icon: icons["IconTrendingUp"],
      children: [
        (bulkOrderMargin.includes(phoneNumber))&&  {
          id: "Bulk Order Margin",
          title: <FormattedMessage id="Upload Margin" />,
          type: "item",
          url: "/bulk/uploadbulkmargin",
        },
        ((bulkOrderDonwload.includes(phoneNumber)||agentRole=='14'))&&  {
          id: "Bulk Order Download",
          title: <FormattedMessage id="Bulk Order Download" />,
          type: "item",
          url: "/bulk/bulkorderdownload",
        },
        ((bulkProductDownload.includes(phoneNumber)||agentRole=='14'))&&  {
          id: "Bulk Product Download",
          title: <FormattedMessage id="Bulk Product Download" />,
          type: "item",
          url: "/bulk/bulkproductdownload",
        },
        (updateZohoCustomerId.includes(phoneNumber))&&  {
          id: "Update Zoho Customer Id",
          title: <FormattedMessage id="Update Zoho Customer Id" />,
          type: "item",
          url: "/bulk/updatezohocustomerid",
        },

      ]
    }
  ],
};
