import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TablePagination, 
  Chip, 
  Button 
} from '@mui/material';
import { LocalShipping, CheckCircle, Pending } from '@mui/icons-material';
import axios from 'axios';
import { useParams,useHistory } from "react-router-dom";

const statusIcons = {
  processing: <Pending />,
  intransit: <LocalShipping />,
  delivered: <CheckCircle />
};

export default function SellerOrders() {
  const { sellerId ,_id} = useParams();
  const history = useHistory()
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);



  const fetchOrders = async () => {
    // Replace this with your actual API call
    // const response = await fetch(`https://api.example.com/seller/orders?page=${page + 1}&limit=${rowsPerPage}`);
    // const data = await response.json();
    // setOrders(data.orders);
    axios.get(process.env.REACT_APP_PATH+`/ondc/crm/order/getOrderListFromCrm?page=${page+1}&limit=${rowsPerPage}&sellerId=${_id}`).then((res)=>{
      if(res.data.success){
        setOrders(res.data.data)
      }
    }).catch((error)=>{
      console.log('error',error)
    })
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchOrders();
  }, [page, rowsPerPage]);


  return (
    <Paper elevation={0}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Total Payable</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order?.name}</TableCell>
                <TableCell>{order?.billing.phone}</TableCell>
                <TableCell>{order.totalPayable.toFixed(2)}</TableCell>
                <TableCell>
                  <Chip
                    icon={statusIcons[order.status]}
                    label={order.status}
                    color={order.status === 'delivered' ? 'success' : 'default'}
                  />
                </TableCell>
                <TableCell>{new Date(order.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{order?.payment?.status}</TableCell>
                <TableCell>
                  <Button variant="outlined" size="small" onClick={()=>history.push(`/ondc/orderdetails/${order._id}`)}>
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={100} // Replace with the total number of orders
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

