"use client"

import { useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Paper,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import axios from "axios"

export default function PaymentLinkModal({ open, onClose,orderDetails }) {
  const [amount, setAmount] = useState("")
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [errorCode, setErrorCode] = useState(null);
  const [linkData, setLinkData] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);

  const handleChangePaymentAmount = (e) => {
    let value = e.target.value;
    let tp = orderDetails.totalPayableAmount;
    let fivePercent = tp * 0.05;
    let Percent70 = tp * 0.7;

    if (value <= tp) {
      if (value < fivePercent || value < 100) {
        setErrorCode(
          `Payment amount should be greater than ${Math.ceil(
            fivePercent > 100 ? fivePercent : 100
          )}`
        );
      } else if (value > Percent70 && value < tp) {
        setErrorCode(
          `Payment shound be less than ${Math.ceil(
            Percent70
          )} or equal to ${tp}`
        );
      } else {
        setErrorCode(null);
      }
      setPaymentAmount(value);
    }
    // if(value)
  };
  

  const createLink = () => {
    setLinkData('')
    axios
      .post(process.env.REACT_APP_PATH + "/products/createPaymentLink", {
        amount: +paymentAmount,
        orderId:orderDetails?._id ,
        phoneNumber: orderDetails.phoneNumber,
      })
      .then((res) => {
        if(res.data.success){
            console.log("res of create link", res.data);
            setLinkData(res.data.message);
        }else{
            alert(res.data.message || "Error in creating link");
        }
      })
      .catch((error) => {
        console.log("error of create link", error);
      });
  };

  const handleCopyLink =async () => {
     try {
      await navigator.clipboard.writeText(linkData);
      setCopySuccess(true);
      setPaymentAmount('')
    } catch (err) {
      setCopySuccess(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent sx={{ p: 4 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Typography>Total Payable Amount : {orderDetails?.totalPayableAmount}</Typography>
          <TextField
            fullWidth
            label="Enter Amount"
            variant="outlined"
            value={paymentAmount}
            onChange={(e) => handleChangePaymentAmount(e)}
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">₹</InputAdornment>,
            }}
            helperText={errorCode}
            error={errorCode == null ? false : true}
          />

          <Button fullWidth variant="outlined" size="large" disabled={errorCode} onClick={createLink}>
            Generate Payment Link
          </Button>

          <Paper
            variant="outlined"
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>{linkData || "Link will come here"}</Box>
            <Button
              variant="outlined"
              startIcon={<ContentCopyIcon />}
              onClick={handleCopyLink}
              disabled={!linkData}
            >
             {copySuccess?'Copied':'Copy Link'} Copy Link
            </Button>
          </Paper>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

