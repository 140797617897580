import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconBuildingStore,
} from "@tabler/icons";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconBuildingStore: IconBuildingStore,
};

var role = getDecryptDataFromLocal("setRole");
var cohortRole = localStorage.getItem("cohortTeam");
var phonenumber = getDecryptDataFromLocal("phoneNumber");

const leadEntryNumber = ["8285805452","9310213570","7982565078","7683043680","9310270360","9354882491",
];

const dashboardAccess = ["1", "2", "5", "14", "18", "12", "17", "18", "15"];
const SpotIncentiveAccess = ["1", "2", "5", "12", "17", "18", "13", "14"];
const MonthWisePerformenceAccess = ["1","2","5","12","17","18","13","14","15",
];
const monthlyIncentiveAccess = ["1", "2", "5", "12", "17", "18", "13", "14"];
const addLeadAccess = ["1", "2", "5", "12", "17", "18", "13", "14"];
const uploadleadAccess = [];
const allleadsAccess = ["1", "2", "5", "12", "17", "18", "13", "14"];
const getOtpAccess = ["1", "5"];
const UploadLeadsTempAccess = ["1", "2", "12", "17", "18", "13", "14"];
const leadEntryAccess = ["1", "2", "12", "17", "18", "13", "14", "15"];
const leadEntryAccessNumber = ["8851245596",'9135739889','9319720027','9306362643','8718836375','8368535431'];
const dialerAccess = ["1", "2", "12", "17", "18", "13", "14", "15"];
const AgentWiseticketReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const agentWiseCallReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const AgentWiseSalesReportAccess = ["1","2","2","3","4","12","17","18","13","14",];
const leadDistributionAccess = ["1", "2", "12", "17", "18", "13", "14"];
const OrdersToBeCancelledAccess = ["1", "2", "12", "17", "18", "13", "14"];
const LeadsBrodcastAccess = ["1", "14", "18"];
const salescallreportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const agentcallreportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const agentperformancereportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const leadDataEntryreportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const CallerWiseSalesReportAccess = ["1"];
const offlineAgentReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const offlineAgentReportNewAccess = ["1", "2", "12", "17", "18", "13", "14"];
const AgentMappingReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const BrandWiseRevenueReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const stateWiseSalesReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const breakUpSalesReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const salesProbablityAccess = ["1", "2", "12", "17", "18", "13", "14"];
const sallerWeeklyCohrtAccess = ["1", "2", "12", "17", "18", "13", "14"];
const SellerWiseSalesAccess = ["1", "2", "12", "17", "18", "13", "14"];
const sellerWiseRTOReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const AgentWiseCoinAndCouponAccess = ["1", "2", "12", "17", "18", "13", "14"];
const FieldAgentReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const StateWiseLeadsReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const DistrictWisePerformenceAccess = ["1","2","5","12","17","18","13","14",];
const AgentWiseMostSoldPincodesAccess = ["1","2","12","17","18","13","14",];
const BulkAgentReportAccess = ["1"];
const SellerTeamLeadConverstionAccess = ["1","2","12","17","18","13","14",];
const distributorEligibleOrdersAccess = ["1","2","12","17","18","13","14",];
const LeadEntryReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const AgentWiseMonthlyIncentiveReportAccess = ["1","2","12","17","18","13","14",];
const SpotIncentiveReportAccess = ["1", "2", "12", "17", "18", "13", "14"];
const CallHistoryByAgent = ["1", "2", "12", "17", "18", "13", "14"];

const salesDashboardAccess = ["1","2","5","11","13","15","14","17","18","12",];
const leadAccess = ["1", "2", "5", "11", "12", "13", "14", "15", "17", "18"];
const reportAccess = ["1", "2", "3", "4", "5", "12", "13", "14", "17", "18"];
const bulkNum = ['9719836187','9667530528','9268096291','9873804039','7532035127','9990619120','7838932786','9315481432','9990337572','9873600942','9821848649','8969708515','9718903103'];
const bulkNum2 = [ "9268096291",'9667530528','9719836187'];
const pincodeBlockByRole = ["1", "14", "17"];


const tagOrderToAgentAccess = ['9719836187','9907125180','8374454823']




const uploadAgentTraget =['9268096291','9719836187']


const viewAsPerRole2 = () => {
  return [
    (salesDashboardAccess.includes(role)||phonenumber=='9315481432') && {
      id: "Dashboard",
      title: <FormattedMessage id="Sales Dashboard" />,
      type: "collapse",
      children: [
        (salesDashboardAccess.includes(role) ||
          dashboardAccess.includes(phonenumber)) && {
          id: "Download Orders",
          title: <FormattedMessage id="Dashboard" />,
          type: "item",
          url: "/sales/dashboard",
          breadcrumbs: false,
        },
        (salesDashboardAccess.includes(role) ||
          dashboardAccess.includes(phonenumber)) && 
          {
          id: "Performance Management",
          title: <FormattedMessage id="Performance Management" />,
          type: "item",
          url: "/sales/performanceManagement",
          breadcrumbs: false,
        },
        // {
        //   id: "seReferenceReport",
        //   title: <FormattedMessage id="Reference Report" />,
        //   type: "item",
        //   url: "/sales/sereferencereport",
        // },
        // {
        //   id: "SewiseBrandPurchaseReport",
        //   title: <FormattedMessage id="Brand Purchase Report" />,
        //   type: "item",
        //   url: "/sales/sewiseBrandPurchaseReport",
        // },
        // {
        //   id: "eligibleOrderSR",
        //   title: <FormattedMessage id="Eligible Orders SR" />,
        //   type: "item",
        //   url: "/sales/eligible-orders-sr",
        // },
        // {
        //   id: "eligibleOrderSplit",
        //   title: <FormattedMessage id="Eligible Orders Split" />,
        //   type: "item",
        //   url: "/sales/eligible-orders-split",
        // },
        (SpotIncentiveAccess.includes(role) ||
          SpotIncentiveAccess.includes(phonenumber)) && {
          id: "Spot Incentive",
          title: <FormattedMessage id="Spot Incentive" />,
          type: "item",
          url: "/sales/incentive",
        },
        (distributorEligibleOrdersAccess.includes(role) ||
          distributorEligibleOrdersAccess.includes(phonenumber)) && {
          id: "Distributor Eligible Orders",
          title: <FormattedMessage id="Distributor Eligible Orders" />,
          type: "item",
          url: "/sales/distributorEligibleOrders",
        },
        (monthlyIncentiveAccess.includes(role) ||
          monthlyIncentiveAccess.includes(phonenumber)) && {
          id: "Monthly Incentive",
          title: <FormattedMessage id="Monthly Incentive" />,
          type: "item",
          url: "/sales/monthlyIncentive",
        },
        (MonthWisePerformenceAccess.includes(role) ||
          MonthWisePerformenceAccess.includes(phonenumber)) && {
          id: "Month Wise Performence",
          title: <FormattedMessage id="Month Wise Performence" />,
          type: "item",
          url: "/sales/month-wise-performence",
        },
        ,
        bulkNum.includes(phonenumber) && {
          id: "Crm Orders",
          title: <FormattedMessage id="Crm Orders" />,
          type: "item",
          url: "/sales/crmorders",
        },
        pincodeBlockByRole.includes(role) && {
          id: "Block Pincode",
          title: <FormattedMessage id="Block Pincode" />,
          type: "item",
          url: "/sales/blockpincode",
        },
      ],
    },
    (leadAccess.includes(role) || phonenumber=='9667530528' || phonenumber=='9873804039'||phonenumber=='8969708515' )&& {
      id: "Leads",
      title: <FormattedMessage id="Leads" />,
      type: "collapse",
      children: [
        (
          tagOrderToAgentAccess.includes(phonenumber)) && {
          id: "Tag Order To Agent",
          title: <FormattedMessage id="Tag Order To Agent" />,
          type: "item",
          url: "/sales/orderTagToAgent",
        },
        (addLeadAccess.includes(role) ||
          addLeadAccess.includes(phonenumber)) && {
          id: "Add Leads",
          title: <FormattedMessage id="Add Leads" />,
          type: "item",
          url: "/sales/addsaleslead",
        },
        (uploadleadAccess.includes(role) ||
          uploadleadAccess.includes(phonenumber)) && {
          id: "Upload Leads",
          title: <FormattedMessage id="Upload Leads" />,
          type: "item",
          url: "/sales/uploadlead",
        },
        (allleadsAccess.includes(role) ||
          allleadsAccess.includes(phonenumber)) && {
          id: "All Leads",
          title: <FormattedMessage id="All Leads" />,
          type: "item",
          url: "/sales/allleads",
        },
        (getOtpAccess.includes(role) || getOtpAccess.includes(phonenumber)) && {
          id: "getOtp",
          title: <FormattedMessage id="Get Otp Online" />,
          type: "item",
          url: "/sales/get-otp",
        },
        (leadEntryAccess.includes(role) ||
        leadEntryAccessNumber.includes(phonenumber)) && {
          id: "Lead Entry",
          title: <FormattedMessage id="Leads Entry" />,
          type: "item",
          url: "/sales/leadEntry",
        },
        (leadDistributionAccess.includes(role) ||
          leadDistributionAccess.includes(phonenumber)) && {
          id: "Lead Distribution",
          title: <FormattedMessage id="Lead Distribution" />,
          type: "item",
          url: "/sales/leadDistribution",
        },
        (UploadLeadsTempAccess.includes(role) ||
          UploadLeadsTempAccess.includes(phonenumber)) && {
          id: "Upload Leads Temp",
          title: <FormattedMessage id="Upload Leads" />,
          type: "item",
          url: "/sales/UploadLeadsTemp",
        },
        (dialerAccess.includes(role) || dialerAccess.includes(phonenumber)) && {
          id: "Dialer",
          title: <FormattedMessage id="Dialer" />,
          type: "item",
          url: "/sales/dialer",
        },
        (LeadsBrodcastAccess.includes(role) ||
          LeadsBrodcastAccess.includes(phonenumber)) && {
          id: "Leads Brodcast",
          title: <FormattedMessage id="Leads Brodcast" />,
          type: "item",
          url: "/sales/leads-brodcast",
        },
        bulkNum2.includes(phonenumber) && {
          id: "Create Order",
          title: <FormattedMessage id="Create Order" />,
          type: "item",
          url: "/sales/CreateOrder",
        },
        {
          id: "Pre Order",
          title: <FormattedMessage id="Pre Order" />,
          type: "item",
          url: "/sales/PreOrder",
        },
        uploadAgentTraget.includes(phonenumber) && {
          id: "Upload Agent Target",
          title: <FormattedMessage id="Upload Agent Target" />,
          type: "item",
          url: "/sales/uploadAgentTarget",
        },
      ],
    },
    reportAccess.includes(role) && {
      id: "Reports",
      title: <FormattedMessage id="Reports" />,
      type: "collapse",
      children: [
        (OrdersToBeCancelledAccess.includes(role) ||
          OrdersToBeCancelledAccess.includes(phonenumber)) && {
          id: "Orders to be Cancelled",
          title: <FormattedMessage id="Orders to be Cancelled" />,
          type: "item",
          url: "/salesReport/orders-to-be-cancelled",
        },
        (salescallreportAccess.includes(role) ||
          salescallreportAccess.includes(phonenumber)) && {
          id: "SalesCallReport",
          title: <FormattedMessage id="Sales Call Report" />,
          type: "item",
          url: "/salesReport/salescallreport",
        },
        (agentcallreportAccess.includes(role) ||
          agentcallreportAccess.includes(phonenumber)) && {
          id: "AgentCallReport",
          title: <FormattedMessage id="Agent Call Report" />,
          type: "item",
          url: "/salesReport/agentcallreport",
        },
        [1,17].includes(Number(role)) && {
          id: "Performance Feedback Report",
          title: <FormattedMessage id="Performance Feedback Report" />,
          type: "item",
          url: "/salesReport/performanceManagementReport",
        },
        (agentperformancereportAccess.includes(role) ||
          agentperformancereportAccess.includes(phonenumber)) && {
          id: "AgentPerformanceReport",
          title: <FormattedMessage id="Agent Performance Report" />,
          type: "item",
          url: "/salesReport/agentperformancereport",
        },
        (LeadEntryReportAccess.includes(role) ||
          LeadEntryReportAccess.includes(phonenumber)) && {
          id: "LeadEntryReport",
          title: <FormattedMessage id="Lead Entry Report" />,
          type: "item",
          url: "/salesReport/leadDataEntryreport",
        },
        (CallerWiseSalesReportAccess.includes(role) ||
          CallerWiseSalesReportAccess.includes(phonenumber)) && {
          id: "CallerwiseSalesReport",
          title: <FormattedMessage id="CallerwiseSalesReport" />,
          type: "item",
          url: "/salesReport/CallerSalesReport",
        },
        (offlineAgentReportAccess.includes(role) ||
          offlineAgentReportAccess.includes(phonenumber)) && {
          id: "offlineSeller",
          title: <FormattedMessage id="Offline Agent Report" />,
          type: "item",
          url: "/salesReport/offlineAgentReport",
        },
        (offlineAgentReportNewAccess.includes(role) ||
          offlineAgentReportNewAccess.includes(phonenumber)) && {
          id: "offlineSellerNew",
          title: <FormattedMessage id="Offline Agent Report(New)" />,
          type: "item",
          url: "/salesReport/offlineAgentReportNew",
        },
        (AgentWiseticketReportAccess.includes(role) ||
          AgentWiseticketReportAccess.includes(phonenumber)) && {
          id: "Agent Wise Ticket Report",
          title: <FormattedMessage id="Agent Wise Ticket Report" />,
          type: "item",
          url: "/salesReport/TicketReport",
        },
        (AgentMappingReportAccess.includes(role) ||
          AgentMappingReportAccess.includes(phonenumber)) && {
          id: "Agent Mapping Report",
          title: <FormattedMessage id="Agent Mapping Report" />,
          type: "item",
          url: "/salesReport/AgentMapping",
        },
        (BrandWiseRevenueReportAccess.includes(role) ||
          BrandWiseRevenueReportAccess.includes(phonenumber)) && {
          id: "BrandWise Revenue Report",
          title: <FormattedMessage id="BrandWise Revenue Report" />,
          type: "item",
          url: "/salesReport/BrandWiseRevenueReport",
        },
        (AgentWiseSalesReportAccess.includes(role) ||
          AgentWiseSalesReportAccess.includes(phonenumber)) && {
          id: "Agent Wise Sales Report",
          title: <FormattedMessage id="Agent Wise Sales Report" />,
          type: "item",
          url: "/salesReport/AgentSalesReport",
        },
        (stateWiseSalesReportAccess.includes(role) ||
          stateWiseSalesReportAccess.includes(phonenumber)) && {
          id: "State/CityWise Sales Report",
          title: <FormattedMessage id="State/CityWise Sales Report" />,
          type: "item",
          url: "/salesReport/stateWiseSalesReport",
        },
        (breakUpSalesReportAccess.includes(role) ||
          breakUpSalesReportAccess.includes(phonenumber)) && {
          id: "Sales Breakup Report",
          title: <FormattedMessage id="Sales Breakup Report" />,
          type: "item",
          url: "/salesReport/breakUpSalesReport",
        },
        (salesProbablityAccess.includes(role) ||
          salesProbablityAccess.includes(phonenumber)) && {
          id: "Sales Probability",
          title: <FormattedMessage id="Sales Probability" />,
          type: "item",
          url: "/salesReport/salesProbablity",
        },
        (sallerWeeklyCohrtAccess.includes(role) ||
          sallerWeeklyCohrtAccess.includes(phonenumber)) && {
          id: "Seller Weekly Cohort",
          title: <FormattedMessage id="Seller Weekly Cohort" />,
          type: "item",
          url: "/seller-weekly-cohort",
        },
        (SellerWiseSalesAccess.includes(role) ||
          SellerWiseSalesAccess.includes(phonenumber)) && {
          id: "Seller Wise Sales",
          title: <FormattedMessage id="Seller Wise Sales" />,
          type: "item",
          url: "/seller-wise-sales",
        },
        (AgentWiseMonthlyIncentiveReportAccess.includes(role) ||
          AgentWiseMonthlyIncentiveReportAccess.includes(phonenumber)) && {
          id: "Monthly Incentive Report",
          title: <FormattedMessage id="Monthly Incentive Report" />,
          type: "item",
          url: "/agent-wise-monthly-incentive-report",
        },
        (SpotIncentiveReportAccess.includes(role) ||
          SpotIncentiveReportAccess.includes(phonenumber)) && {
          id: "Spot Incentive Report",
          title: <FormattedMessage id="Spot Incentive Report" />,
          type: "item",
          url: "/spot-incentive-report",
        },
        (CallHistoryByAgent.includes(role) ||
          CallHistoryByAgent.includes(phonenumber)) && {
          id: "Call History By Agent",
          title: <FormattedMessage id="Call History By Agent" />,
          type: "item",
          url: "/callhistorybyagent",
        },
        (AgentWiseCoinAndCouponAccess.includes(role) ||
          AgentWiseCoinAndCouponAccess.includes(phonenumber)) && {
          id: "Agent Wise Coin & Coupon Report",
          title: <FormattedMessage id="Agent Wise Coin & Coupon Report" />,
          type: "item",
          url: "/agent-wise-coin-coupon-report",
        },
        (FieldAgentReportAccess.includes(role) ||
          FieldAgentReportAccess.includes(phonenumber)) && {
          id: "Field Agent Report",
          title: <FormattedMessage id="Field Agent Report" />,
          type: "item",
          url: "/field-agent-report",
        },
        (StateWiseLeadsReportAccess.includes(role) ||
          StateWiseLeadsReportAccess.includes(phonenumber)) && {
          id: "Statewise Leads Report",
          title: <FormattedMessage id="Statewise Leads Report" />,
          type: "item",
          url: "/statewise-leads-report",
        },
        (DistrictWisePerformenceAccess.includes(role) ||
          DistrictWisePerformenceAccess.includes(phonenumber)) && {
          id: "District Wise Performence",
          title: <FormattedMessage id="District Wise Performence" />,
          type: "item",
          url: "/sales/district-wise-performence",
        },
        (AgentWiseMostSoldPincodesAccess.includes(role) ||
          AgentWiseMostSoldPincodesAccess.includes(phonenumber)) && {
          id: "Agent Wise Most Sold Pincodes",
          title: <FormattedMessage id="Agent Wise Most Sold Pincodes" />,
          type: "item",
          url: "/sales/agent-wise-most-sold-pincodes",
        },
        (BulkAgentReportAccess.includes(role) ||
          BulkAgentReportAccess.includes(phonenumber)) && {
          id: "Bulk Agent Report",
          title: <FormattedMessage id="Bulk Agent Report" />,
          type: "item",
          url: "/sales/bulk-agent-report",
        },
        (SellerTeamLeadConverstionAccess.includes(role) ||
          SellerTeamLeadConverstionAccess.includes(phonenumber)) && {
          id: "Seller Team Lead Converstion",
          title: <FormattedMessage id="Seller Team Lead Converstion" />,
          type: "item",
          url: "/sales/seller-team-lead-converstion",
        },
        (["1","17","18"].includes(role) ||
        ["9907125180"].includes(phonenumber))&&
        {
          id: "Agent Attendance",
          title: <FormattedMessage id="Agent Attendance" />,
          type: "item",
          url: "/salesReport/agentAttendance",
        }
      ],
    },
  ];
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//
export const Dashboard = {
  id: "Sales Dashboard",
  title: <FormattedMessage id="Sales" />,
  type: "group",
  children: viewAsPerRole2(),
};
